import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  DirectionsRenderer,
  Polyline,
  DirectionsService,
} from '@react-google-maps/api';
import { useRouter } from 'next/router';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const DynamicListingMapByAttribute = ({ styles, attributes, data }) => {
  const containerStyle = {
    width: '100%',
    height: '100%',
  };
  const [directions, setDirections] = useState(null);

  const departure_attribute = attributes?.find(
    (attributes) => attributes.id == data?.destination_attribute_id
  );
  const arrival_attribute = attributes?.find(
    (attributes) => attributes.id == data?.arrival_attribute_id
  );

  //
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key ??
      'AIzaSyBr3Q8BS3eMq4dA0u0wrkUtW_VWgYLbtB0',
  });

  const startPoint = {
    lat: departure_attribute?.values?.[0]?.metadata?.latitude,
    lng: departure_attribute?.values?.[0]?.metadata?.longitude,
  };
  const endPoint = {
    lat: arrival_attribute?.values?.[0]?.metadata?.latitude,
    lng: arrival_attribute?.values?.[0]?.metadata?.longitude,
  };
  const defaultCenter = { lat: 0, lng: 0 };

  const directionsCallback = useCallback((response) => {
    if (response !== null) {
      if (response.status === 'OK' && directions == null) {
        setDirections(response);
      } else {
        // console.log('Directions response error:', response);
      }
    }
  });

  console.log(data?.arrival_icon);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={get_dynamic_styles(styles?.main_styles)}
      center={startPoint?.lat && startPoint?.lng ? startPoint : defaultCenter}
      zoom={8}
    >
      {data?.travel_mode === 'flight' && (
        <>
          {' '}
          <Polyline
            path={[startPoint, endPoint]}
            options={{ strokeColor: '#666666', strokeWeight: 3 }}
          />
          {startPoint?.lat && (
            <Marker icon={data?.departure_icon} position={startPoint} />
          )}
          {endPoint?.lat && (
            <Marker icon={data?.arrival_icon} position={endPoint} />
          )}
        </>
      )}

      {data?.travel_mode === 'driving' && (
        <>
          <DirectionsService
            options={{
              destination: endPoint,
              origin: startPoint,
              travelMode: 'DRIVING',
            }}
            callback={directionsCallback}
          />
          <DirectionsRenderer
            options={{
              directions: directions,
            }}
          />
        </>
      )}
    </GoogleMap>
  ) : (
    <CustomLoading />
  );
};

export default DynamicListingMapByAttribute;
