import React, { useEffect, useState } from 'react';
import PricingRuleForm from './PricingRuleForm';
import NotFoundAlert from '../../Shared/Default UI/NotFoundAlert';
import {
  delete_icon,
  edit_icon,
  pencil_icon,
} from '../../Shared/Constant/Icons/AllIcons';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { delete_pricing_rule, post_pricing_rule } from '../Actions/PricingRule';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import SetPasswordPageLayout from '../../../themes/common_layouts/SetPasswordPageLayout';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { useAvailableBlockKeys } from '../../../hooks/useAvailableBlockKeys';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const EditPricingRules = ({
  onChangeData,
  getData,
  variantsArray,
  schedulesArray,
  listing_id,
}) => {
  const [PricingRulesArray, setPricingRulesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const add_listing_current_blocks = useAvailableBlockKeys(
    TYPE_CONSTANT.PAGES?.add_listing
  );
  //
  const fetch_list = () => {
    axios
      .post('/api/pricing_rules', {
        listing_id,
      })
      .then((res) => {
        setPricingRulesArray(res.data.listing_price_rules);
      });
  };
  useEffect(() => {
    fetch_list();
  }, []);

  //  states
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [ruleObject, setRuleObject] = useState({
    title: null,
    description: null,
    listing_variant_id: null,
    listing_schedule_id: null,
    list_price: null,
    rule_type: 1,
    rule_value: 0,
    min_quantity: null,
    expirable: false,
    active: true,
  });

  // functions
  const setRule = () => {
    post_pricing_rule({
      listing_id,
      rule_id: ruleObject?.id,
      data: ruleObject,
      setIsLoading: setIsLoading,
      onSuccess: fetch_list,
    });

    clearForm();
    setIsFormOpen(false);
  };

  const clearForm = () => {
    setRuleObject({
      title: null,
      description: null,
      listing_variant_id: null,
      listing_schedule_id: null,
      list_price: null,
      rule_type: 1,
      rule_value: 0,
      min_quantity: null,
      expirable: false,
      active: true,
    });
  };

  return (
    add_listing_current_blocks.includes('pricing_rules') && (
      <div className="  w-full">
        {isLoading && <CustomLoading />}
        {/* List  */}
        <div className=" w-full grid grid-cols-1 gap-6 bg-white shadow-c-sm p-4 ">
          <h3 className=" font-semibold text-primary text-xl   text-start">
            {Add_product_text.addpricingrule.pricing_rules}
          </h3>
          {!PricingRulesArray === null || PricingRulesArray?.length > 0 ? (
            <div className="flex flex-col gap-6">
              {PricingRulesArray?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className=" w-full border border-gray-200 border-opacity-70 rounded-card   px-2 py-3 flex gap-3 items-start "
                  >
                    <CurrencyDollarIcon className="w-6   text-gray-700" />
                    <div className="  flex-grow">
                      <p className="text-base leading-4 text-[#121212] font-medium">
                        {item.title}
                      </p>
                      <p className=" text-xs leading-4 font-medium text-default_gray mt-[2px] ">
                        {item.list_price?.formatted}
                      </p>
                    </div>
                    <div className="flex flex-col   relative  end-0 gap-1.5 ">
                      <button
                        className="text-gray-700"
                        onClick={() => {
                          setRuleObject({
                            id: item?.id,
                            title: item.title,
                            description: item.description,
                            listing_variant_id:
                              item.listing_variant_id != 0
                                ? item.listing_variant_id
                                : null,
                            listing_schedule_id:
                              item.listing_schedule_id != 0
                                ? item.listing_schedule_id
                                : null,
                            list_price: item.list_price?.amount,
                            rule_type: item.rule_type,
                            rule_value: item.rule_value,
                            min_quantity: item.min_quantity,
                            expirable: item.expirable,
                            active: item.active,
                          });
                          setIsFormOpen(true);
                        }}
                      >
                        {pencil_icon}
                      </button>
                      <button
                        className="text-gray-700"
                        onClick={() => {
                          delete_pricing_rule({
                            listing_id,
                            rule_id: item.id,
                            onSuccess: fetch_list,
                            setIsLoading: setIsLoading,
                          });
                        }}
                      >
                        {delete_icon}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <NotFoundAlert alert_text={'No rules found '} />
          )}
          <div className="mt-3 flex items-center justify-center ">
            {!isFormOpen && (
              <button
                className="border  border-primary rounded-button text-primary text-center px-6 py-1"
                onClick={() => setIsFormOpen(true)}
              >
                {Add_product_text.addpricingrule.add_pricing_rule}
              </button>
            )}
          </div>
        </div>

        {/* Form */}
        {isFormOpen && (
          <div className="mt-6  ">
            <PricingRuleForm
              ruleObject={ruleObject}
              setRuleObject={setRuleObject}
              setRule={setRule}
              schedulesArray={schedulesArray}
              variantsArray={variantsArray}
            />
          </div>
        )}
      </div>
    )
  );
};
export default EditPricingRules;
