import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  get_orders,
  store_orderSelector,
} from '../../store/feature/store_orderSlice';
import NoOrdersItem from '../../components/MyStore/orders/NoOrdersItem/NoOrdersItem';
import OrdersFilter from '../../components/MyStore/orders/OrdersFilter/OrdersFilter';
import OrdersItem from '../../components/MyStore/orders/OrdersItem/OrdersItem';
import {
  filter_id,
  filter_name,
} from '../../components/Shared/Constant/Status';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Orders_text } from '../../constant/Translation/OrdersText';
import OrdersCalenderView from '../../components/MyStore/OrderDetails/Calender/CalenderView';
import {
  calender_icon,
  calender_icon2,
  list_icon,
} from '../../components/Shared/Constant/Icons/AllIcons';
import OrdersPageHeader from '../../components/MyStore/orders/OrdersPageHeader';

const StoreOrdersPageLayout = () => {
  const [isShowCalender, setIsShowCalender] = useState(false);

  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);
  const router = useRouter();
  const accountId = router.query.account_id;

  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      if (router.query.order_status) {
        dispatch(
          get_orders({
            authKey: auth_key,
            bodyParam: {
              page: router.query?.page ? router.query?.page : 1,
              per_page: 30,
              account_id: accountId,
              order_status: filter_id(router.query.order_status),
            },
          })
        );
      } else {
        dispatch(
          get_orders({
            authKey: auth_key,
            bodyParam: {
              page: router.query?.page ? router.query?.page : 1,
              per_page: 30,
              account_id: accountId,
            },
          })
        );
      }
    }
  }, [auth_key, router.query]);

  const { orders, total_records, page, isFetching } =
    useSelector(store_orderSelector);

  useEffect(() => {
    const totalpage = Math.ceil(total_records / 30);
    if (page === 1 && total_records === 0) {
      setPageCount(total_records);
    }
    if (Number(total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [total_records]);

  const moreOrders = (data) => {
    if (router.query.order_status) {
      router.push({
        query: {
          account_id: router.query.account_id,
          page: Number(data.selected) + 1,
          order_status: filter_name(router.query.order_status),
        },
      });
    } else {
      router.push({
        query: {
          account_id: router.query.account_id,
          page: Number(data.selected) + 1,
        },
      });
    }
  };

  return (
    <div className=" mt-4">
      {isFetching && <CustomLoading />}
      <OrdersPageHeader
        isShowCalender={isShowCalender}
        setIsShowCalender={setIsShowCalender}
      />

      {/* Calender view */}
      {isShowCalender && <OrdersCalenderView orders={orders} />}

      {/* Normal View */}
      {!isShowCalender && (
        <>
          {orders === null || orders?.length > 0 ? (
            <div>
              <OrdersItem orders={orders} accountId={accountId} />
            </div>
          ) : (
            <div>
              <NoOrdersItem />
            </div>
          )}
          <div className="mt-12   flex justify-center pb-12 ">
            {orders !== null && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                onPageChange={(data) => moreOrders(data)}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                renderOnZeroPageCount={null}
                containerClassName=""
                className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                pageLinkClassName="px-4 py-2 border"
                previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                disabledLinkClassName=""
                prevPageRel="2"
                forcePage={page - 1}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StoreOrdersPageLayout;
