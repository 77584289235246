import React, { useEffect, useMemo, useState } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';
import SelectFromListDropDown from '../../../DynamicUI/FormFields/SelectFromListDropdown';
import { Listing_text } from '../../../constant/Translation/Listing';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  cartSelector,
  getSchedulesData,
} from '../../../store/feature/cartSlice';
import { getTimeDifference } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import CheckoutPageLayout from '../../../themes/blank/CheckoutPageLayout';
import SidePan from '../../Shared/Modal/SidePan';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import PricingRuleSelect from '../../Cart/PricingRules/PricingRuleSelect';
import { cn } from '../../../utils/classNames';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import axios from 'axios';
import { calculateDayDifference } from '../../../utils/calculateScheduleTime';

const AdvanceBookNowSection = ({
  block_code,
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
  styles,
  isOwner,
  data,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { schedules_per_day, isSchedulesFetching, isFetching, cart_details } =
    useSelector(cartSelector);

  //
  const [selected_date, setSelectedDate] = useState(null);
  const [selected_schedule, setSelectedSchedule] = useState(null);
  const [items_in_card, setItemsInCart] = useState(1);
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const [selected_pricing, setSelectedPricing] = useState(null);

  //
  const onClickBookNowButton = (e) => {
    if (data?.is_show_date && selected_date == null) {
      setShowError(true);
      setError_message(Listing_text.product.select_date);
      return false;
    }
    if (data?.is_show_schedule && selected_schedule == null) {
      setShowError(true);
      setError_message(Listing_text.productschedule.select_schedule);
      return false;
    }
    if (items_in_card == 0) {
      setShowError(true);
      setError_message('Select minimum quantity');
      return false;
    }

    const cartData = {
      cart: {
        listing_id: listing_details?.id,
        quantity: items_in_card,
        uuid: TYPE_CONSTANT.UUID,
        listing_schedule_id: selected_schedule?.id,
        listing_price_rule_id: selected_pricing?.id,
        schedule_start_at: `${selected_date} ${selected_schedule?.start_time}:00`,
        schedule_end_at: `${selected_date} ${selected_schedule?.end_time}:00`,
        metadata: {
          day: schedules_per_day[0]?.day,
          start_at: selected_schedule?.start_time,
          end_at: selected_schedule?.end_time,
        },
      },
    };

    if (selectedVariant !== null) {
      cartData.cart['variant_id'] = selectedVariant;
    }

    dispatch(addToCart({ data: cartData })).then((res) => {
      if (!res.payload.code) {
        push_data_layer({
          event: 'book_now',
          data: {
            name: listing_details?.title,
            slug: listing_details?.slug,
            price: listing_details?.offer_price.amount,
            stock: listing_details?.stock,
            category: {
              id: listing_details?.categories[0]?.id,
              name: listing_details?.categories[0]?.name,
            },
            image: listing_details?.images,
            url: window.location.href,
          },
        });
        handleSidepanOpen(e);
        // router.push('/checkout?type=events');
      } else {
        if (res?.payload?.code === 489) {
          clear_cart(e);
        } else {
          setShowError(true);
          setError_message(res.payload.message);
        }
      }
    });
  };

  // clear cart
  const clear_cart = (e) => {
    if (cart_details && cart_details?.length > 0) {
      axios
        .delete('/api/cart/delete_all_cart')
        .then((res) => {
          onClickBookNowButton(e);
        })
        .catch((error) => {
          setShowError(true);
          setError_message(error?.response?.data?.message);
        });
    } else {
      onClickBookNowButton(e);
    }
  };
  //
  const onChangeDate = (date) => {
    setSelectedSchedule(null);
    dispatch(
      getSchedulesData({
        id: listing_details?.id,
        bodyParam: { days: 1, start_at: date },
      })
    );
  };

  //
  const updateCartQuantity = (is_increase) => {
    if (selected_schedule == null) {
      setShowError(true);
      setError_message(Listing_text.productschedule.select_schedule);
      return false;
    }
    if (
      selected_schedule?.stocks_left &&
      items_in_card == selected_schedule?.stocks_left &&
      is_increase
    ) {
      setShowError(true);
      setError_message(Checkout_text.cart.alert_stock_available);
      return false;
    }
    if (
      selected_schedule?.stocks_left &&
      selected_pricing &&
      selected_pricing?.rule_type == 3 &&
      items_in_card + selected_pricing?.rule_value >
        selected_schedule?.stocks_left &&
      is_increase
    ) {
      setShowError(true);
      setError_message(Checkout_text.cart.alert_stock_available);
      return false;
    }

    setItemsInCart(
      is_increase
        ? selected_pricing?.rule_type == 3
          ? items_in_card + selected_pricing?.rule_value
          : items_in_card + 1
        : items_in_card > 1
        ? selected_pricing?.rule_type == 3
          ? items_in_card - selected_pricing?.rule_value
          : items_in_card - 1
        : items_in_card
    );
  };

  //handleSidepanOpen
  const handleSidepanOpen = (e) => {
    e.stopPropagation();
    setSidePanOpen(true);
  };

  // in memo
  const schedules_list = useMemo(() => {
    return schedules_per_day?.[0]?.schedules
      .filter((item) => item?.available !== false)
      ?.map((schedule) => {
        const calc_time = calculateDayDifference(
          schedule.start_time,
          schedule.end_time
        );

        return {
          label:
            calc_time > 0 ? (
              <p>
                {`${schedule.start_time} to ${schedule.end_time}`}
                <sup>+{calc_time}</sup> (
                {dynamic_translation_set(
                  Listing_text.product.stock_text,
                  schedule?.stocks_left
                )}
                )
              </p>
            ) : (
              <p>
                {`${schedule.start_time} to ${schedule.end_time}`} (
                {dynamic_translation_set(
                  Listing_text.product.stock_text,
                  schedule?.stocks_left
                )}
                )
              </p>
            ),
          value: schedule,
        };
      });
  }, [schedules_per_day]);

  // at first time
  useEffect(() => {
    const today_date = new Date();
    setSelectedDate(today_date.toISOString().split('T')[0]);
    onChangeDate(today_date.toISOString().split('T')[0]);
  }, []);

  return (
    <>
      <div style={get_dynamic_styles(styles?.main_styles)}>
        {isFetching && <CustomLoading />}

        <div
          style={{
            display: styles?.box_styles?.display,
            gridTemplateColumns: dynamic_text_convert({
              main_text: styles?.box_styles?.columns_box_percentage,
              check_by: '-1%',
              value: '',
            }),
            ...get_dynamic_styles(styles?.box_styles),
          }}
        >
          {data?.title && (
            <p style={get_dynamic_styles(styles?.title_style)}>{data?.title}</p>
          )}
          {/* date */}
          {data?.is_show_date && (
            <input
              type="date"
              className=" ransition  duration-700 border-0 outline-none
                    focus:ring-0 focus:border-primary
                  "
              style={get_dynamic_styles(styles?.date_filed_style)}
              onChange={(e) => {
                setSelectedDate(e.target.value);
                onChangeDate(e.target.value);
              }}
              value={selected_date}
              disabled={isSchedulesFetching}
            />
          )}

          {/* schedule */}
          {data?.is_show_schedule && (
            <SelectFromListDropDown
              default_option_text={Listing_text.productschedule.select_schedule}
              drop_down_items={schedules_list?.length > 0 ? schedules_list : []}
              styles={styles}
              set_new_value={(value) => setSelectedSchedule(value)}
              current_value={selected_schedule}
              is_disabled={isSchedulesFetching}
              not_found_text={
                Listing_text.productschedule.on_schedule_available
              }
              is_show_down_icon={true}
              is_loading={isSchedulesFetching}
            />
          )}

          {/*  */}
          {data?.is_show_pricing_rules && (
            <PricingRuleSelect
              selected_pricing={selected_pricing}
              setSelectedPricing={setSelectedPricing}
              selectedDate={selected_date}
              styles={styles}
              listing_id={listing_details?.id}
              schedule_id={selected_schedule?.id}
              setCartQuantity={setItemsInCart}
              stocks_left={selected_schedule?.stocks_left}
              data={data}
            />
          )}

          {/* limit button */}
          <div
            className="   border border-primary   flex  overflow-hidden "
            style={get_dynamic_styles(styles?.quantity_section_styles)}
          >
            <button
              onClick={() => updateCartQuantity(false)}
              className={cn(
                'h-full  aspect-1 bg-primary  rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white ',
                selected_pricing?.rule_type === 2 &&
                  '!text-gray-500 !bg-gray-200  cursor-not-allowed '
              )}
              disabled={selected_pricing?.rule_type === 2}
            >
              -
            </button>
            <span className=" flex-grow text-sm leading-4 font-medium text-[#4A4A4A] mx-3 text-center">
              {items_in_card}
            </span>
            <button
              onClick={() => updateCartQuantity(true)}
              className={cn(
                'h-full  aspect-1 bg-primary  rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white ',
                selected_pricing?.rule_type === 2 &&
                  '!text-gray-500 !bg-gray-200  cursor-not-allowed '
              )}
              disabled={selected_pricing?.rule_type === 2}
            >
              +
            </button>
          </div>

          {/* price */}

          {data?.is_show_price && (
            <p style={get_dynamic_styles(styles?.price_style)}>
              {listing_details?.offer_price?.formatted}
            </p>
          )}

          {/* book now button */}
          <button
            className="flex cursor-pointer"
            onClick={(e) => clear_cart(e)}
            style={get_dynamic_styles(styles?.book_button_style)}
          >
            {Listing_text.product.book_now}
          </button>
        </div>
      </div>
      {/* Sidepan */}
      <SidePan
        id={`event-details-sidepan-${listing_details?.id}`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
        modal_section_styles={
          'fixed  right-0 left-0 bottom-0 lg:top-0 lg:my-auto   z-[60] overflow-hidden flex items-center  justify-center   rounded-t-[40px]   lg:rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full lg:max-w-2xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
        modal_width={'max-w-lg w-full'}
        is_close_by_outside_click={false}
      >
        <div className=" relative max-h-[75vh]">
          <button
            className=" fixed  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-40 text-primary"
            onClick={() => {
              setSidePanOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {sidePanOpen && <CheckoutPageLayout type={'events'} />}
        </div>
      </SidePan>
    </>
  );
};

export default AdvanceBookNowSection;
