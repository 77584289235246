import React from 'react';
import { Subscription_text } from '../../constant/Translation/SubscriptionText';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const UpgradeCard = ({ styles, data }) => {
  //* title
  // * description
  // * button_title
  // *
  return (
    <div
      className={
        !styles?.main_styles && ' bg-white shadow rounded-md    p-5 md:p-9 '
      }
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <div
        className={!styles?.box_styles && 'flex gap-20 '}
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {/* 1st section */}
        <div className="flex flex-col items-start gap-3">
          <div>
            <p
              className={!styles?.sub_title_style && ' text-base text-gray-400'}
              style={get_dynamic_styles(styles?.sub_title_style)}
            >
              {Subscription_text.subscription.upgrade_card_sub_title}
            </p>
            <p
              className={!styles?.title_style && ' text-sm text-gray-700'}
              style={get_dynamic_styles(styles?.title_style)}
            >
              {Subscription_text.subscription.upgrade_card_title}
            </p>
          </div>
          <p
            className={!styles?.desc_style && ' text-sm text-gray-700'}
            style={get_dynamic_styles(styles?.desc_style)}
          >
            {Subscription_text.subscription.upgrade_card_description}
          </p>

          <button
            className={!styles?.button_style && 'btn-primary-fill'}
            style={get_dynamic_styles(styles?.button_style)}
          >
            {Subscription_text.subscription.upgrade_card_button_title}
          </button>
        </div>

        {/* image */}
        <img
          src="https://media.tradly.app/images/beauty/84637/Group696-15tRyfIY.png"
          alt="upgrade-image"
          className={!styles?.image_style && 'w-40 h-40  object-cover'}
          style={get_dynamic_styles(styles?.image_style)}
        />
      </div>
    </div>
  );
};

export default UpgradeCard;
