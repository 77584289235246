/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  getListingUpdates,
  getListingVariants,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';

import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  DefaultColumnStyles,
  ListingDetailsBoxDefaultStyles,
  ListingDetailsMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import Cookies from 'js-cookie';

import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { cartList, cartSelector } from '../../store/feature/cartSlice';
import { negotiateSelector } from '../../store/feature/negotiate/negotiateSlice';

import {
  editor_versions_list,
  latest_editor_version,
} from '../../constant/Version';

import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import SuccessPopup from '../../components/Shared/PopUp/SuccessPopup';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { listing_details_component_decide } from './BlockDecide/ListingDetailsBlockDecide';
import { useScreenSize } from '../../hooks/useScreenSize';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { check_login } from '../../constant/check_auth';

const ProductDetailsPageLayout = ({}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { width, height } = useScreenSize();

  const ListingDetailsPageStyle = TYPE_CONSTANT.PAGES?.listing_details;

  // States
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // Selectors
  const {
    listing_details,
    rating_data,
    errorMessage,
    isError,
    reviews,
    my_review,
    review_page,
    review_total_records,
    isShowSuccess,
    success_message,
  } = useSelector(listingSelector);

  const { cart_details } = useSelector(cartSelector);
  const { login } = useSelector(authSelector);

  // Effects
  useEffect(() => {
    if (listing_details) {
      dispatch(getListingVariants({}));
      dispatch(
        getListingReviews({
          params: {
            type: 'listings',
            id: listing_details?.id,
            page: 1,
          },
        })
      );
      dispatch(
        getListingQNA({
          params: {
            reference_type: 'listings_qa',
            reference_id: listing_details?.id,
            page: 1,
            per_page: 10,
            parent: 0,
          },
        })
      );
      dispatch(
        getListingUpdates({
          params: {
            reference_type: 'updates',
            reference_id: listing_details?.id,
            page: 1,
            per_page: 10,
            parent: 0,
          },
        })
      );
      // calling cartList again from heré
      dispatch(cartList({}));
    }
  }, [listing_details]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        }
      });
    }
  };

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  // Is Owner
  const [isOwner, setIsOwner] = useState(false);
  useEffect(() => {
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`))
    ) {
      if (
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [listing_details]);

  // Hash anchror tag:
  useEffect(() => {
    const path = window.location.hash;
    if (path && path.includes('#') && listing_details) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);
        if (el) {
          const r = el.getBoundingClientRect();
          window.top.scroll({
            top: pageYOffset + r.top,
            behavior: 'smooth',
          });
        }
      }, 600);
    }
  }, [listing_details]);

  // negotiate error handle
  const {
    isShowSuccess: negotiateSuccess,
    isError: negotiateError,
    errorMessage: negotiateErrorMessage,
  } = useSelector(negotiateSelector);

  useEffect(() => {
    if (negotiateError) {
      setShowError(true);
      setError_message(negotiateErrorMessage);
    }
  }, [negotiateError]);

  return (
    <div
      style={get_dynamic_styles_with_default_value(
        responsive_design_styles({
          parent_group: ListingDetailsPageStyle?.styles,
          child_group_name: 'main_styles',
          width,
        }),

        ListingDetailsMainDefaultStyles
      )}
    >
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Success popup */}
      {isShowSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isShowSuccess && dispatch(clearListingState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopup message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {listing_details !== undefined ? (
        <div
          className="flex flex-col  "
          style={get_dynamic_styles_with_default_value(
            responsive_design_styles({
              parent_group: ListingDetailsPageStyle?.styles,
              child_group_name: 'box_styles',
              width,
            }),
            ListingDetailsBoxDefaultStyles
          )}
        >
          {/* Rows */}

          {ListingDetailsPageStyle?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });

            return (
              <div
                className={`  `}
                style={
                  current_editor_version == latest_editor_version
                    ? {
                        display: row_styles?.display,
                        gridTemplateColumns: dynamic_text_convert({
                          main_text: row_styles?.columns_box_percentage,
                          check_by: '-1%',
                          value: '',
                        }),
                        ...get_dynamic_styles(row_styles),
                      }
                    : {
                        display:
                          row_styles?.isShowInMobile === false
                            ? 'none'
                            : 'grid',
                        gap: row_styles?.gap_between_columns ?? '24px',
                        gridTemplateColumns:
                          width > 768
                            ? row_styles?.columns_box_percentage
                              ? row_styles?.columns_box_percentage
                              : `repeat(${
                                  row.columns?.length ?? 1
                                }, minmax(0, 1fr))`
                            : row?.['768_row_styles']
                            ? row_styles?.columns_box_percentage
                            : '100%',
                        ...get_dynamic_styles(row_styles),
                      }
                }
              >
                {/* Columns */}
                {columns?.map((column, index) => {
                  const current_column_style = responsive_column_styles({
                    parent_group: column_styles,
                    index,
                    width,
                  });

                  return (
                    <div
                      style={{
                        display: current_column_style?.display
                          ? current_column_style?.display
                          : 'flex',
                        ...get_dynamic_styles_with_default_value(
                          current_column_style,
                          DefaultColumnStyles
                        ),
                      }}
                    >
                      {column?.map((item) => {
                        return (
                          listing_details_component_decide({
                            ...item,
                            listing_details,
                            rating_data,
                            errorMessage,
                            reviews,
                            my_review,
                            like,
                            isOwner,
                            selectedVariant,
                            setSelectedVariant,
                            setShowError,
                            setError_message,
                          }) &&
                          (editor_versions_list?.includes(
                            current_editor_version
                          ) ? (
                            listing_details_component_decide({
                              ...item,
                              listing_details,
                              rating_data,
                              errorMessage,
                              reviews,
                              my_review,
                              like,
                              isOwner,
                              selectedVariant,
                              setSelectedVariant,
                              setShowError,
                              setError_message,
                            })
                          ) : (
                            <div>
                              {listing_details_component_decide({
                                ...item,
                                listing_details,
                                rating_data,
                                errorMessage,
                                reviews,
                                my_review,
                                like,
                                isOwner,
                                selectedVariant,
                                setSelectedVariant,
                                setShowError,
                                setError_message,
                              })}
                            </div>
                          ))
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <NotFound message={Listing_text.product.no_product_found} />
      )}
    </div>
  );
};

export default ProductDetailsPageLayout;
