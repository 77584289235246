import React, { useEffect, useState } from 'react';
import { all_listing_types } from '../../../constant/ListingTypesData';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';

const OrderTypeSelection = ({ order_type, set_order_type }) => {
  //
  const type_validate = (type) => {
    const selected_type_by_admin =
      TYPE_CONSTANT.PAGES?.add_listing_form?.add_listing_types;

    if (selected_type_by_admin?.length > 0) {
      return selected_type_by_admin?.includes(type) ? true : false;
    } else {
      return true;
    }
  };

  //
  const [orderTypes, setOrderTypes] = useState([]);

  useEffect(() => {
    const order_types = [
      { title: Add_product_text.addproduct.physical_product, key: 'listings' },
      { title: Add_product_text.addproduct.event_booking, key: 'events' },
      { title: Add_product_text.addproduct.digital_product, key: 'digital' },
      {
        title: Add_product_text.addproduct.appointment_booking,
        key: 'appointments',
      },
      { title: Add_product_text.addproduct.crowdfunding, key: 'donation' },
    ];

    setOrderTypes(order_types.filter((type) => type_validate(type?.key)));
  }, []);

  return (
    orderTypes?.length > 1 && (
      <div className="  flex items-center flex-wrap gap-3">
        {orderTypes.map((type) => {
          return (
            <button
              className={
                order_type !== type.key
                  ? ' min-w-[100px] h-5 px-6 py-4 border border-default_gray flex items-center justify-between rounded-button    transition duration-700    hover:border-primary hover:text-primary'
                  : ' min-w-[100px] h-5 px-6 py-4 border   flex items-center justify-between    border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2 '
              }
              key={type.key}
              onClick={() => set_order_type(type.key)}
            >
              <span className=" text-base   font-semibold ltr:mr-3 rtl:ml-3">
                {type.title}
              </span>
              {order_type === type.key && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5   ml-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              )}
            </button>
          );
        })}
      </div>
    )
  );
};

export default OrderTypeSelection;
