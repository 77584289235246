/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../../Shared/Constant/Constant';
import {
  changeStatus,
  currentOrderStatus,
  orderStatus,
} from '../../../Shared/Constant/Status';
import { useRouter } from 'next/dist/client/router';
import { Orders_text } from '../../../../constant/Translation/OrdersText';

const OrdersItem = ({ orders, accountId }) => {
  const router = useRouter();
  return (
    <div>
      <div className=" grid  grid-cols-[60%,40%]  xs:grid-cols-[40%,30%,30%]   c-lg:grid-cols-[40%,20%,20%,20%] px-2">
        <p className=" text-lg text-[#000000] text-opacity-40">
          {Orders_text.orderlist.history}
        </p>
        <p className="hidden c-lg:block text-lg text-[#000000] text-opacity-40 text-center">
          {Orders_text.orderlist.date}
        </p>
        <p className=" text-lg text-[#000000] text-opacity-40 text-center">
          {Orders_text.orderlist.total_earnings}
        </p>
        <p className=" hidden xs:block text-lg text-[#000000] text-opacity-40 text-center">
          {Orders_text.orderlist.status}
        </p>
      </div>
      {orders ? (
        <div className=" mt-8">
          {orders?.map((item) => {
            let listing_details = item?.order_details[0]?.listing;
            let listing_variant_details = item?.order_details[0]?.variant
              ? Object?.keys(item?.order_details[0]?.variant)?.length > 0
                ? item?.order_details[0]?.variant
                : undefined
              : undefined;

            let item_details = listing_variant_details
              ? listing_variant_details
              : listing_details;
            return (
              <Link
                href={{
                  pathname: `/a/orders/${item.reference_number}`,
                  query: { account_id: accountId },
                }}
                key={Math.random()}
              >
                <a>
                  <div
                    key={Math.random()}
                    // onClick={() => {
                    //   router.push({
                    //     pathname: `/a/orders/${item.id}`,
                    //     query: { account_id: accountId },
                    //   });
                    // }}
                  >
                    <div className=" grid  grid-cols-[60%,40%] xs:grid-cols-[40%,30%,30%]   c-lg:grid-cols-[40%,20%,20%,20%] items-center bg-white shadow-c-sm rounded-card   mb-3 py-3 px-2 cursor-pointer">
                      <div className=" flex   items-center ">
                        <div className=" flex-none w-10 h-10 relative ml-2  sm:ml-8  mr-2 sm:mr-8 rounded-image overflow-hidden">
                          {item_details?.images?.length > 0 && (
                            <img
                              src={getThumbnailImage(item_details.images[0])}
                              className="h-full w-full object-cover object-left-top"
                              alt="image"
                            />
                          )}
                        </div>
                        <div>
                          <p className=" text-lg font-semibold text-primary">
                            {item_details?.title}
                          </p>

                          <p className="  text-sm text-[#77869E] leading-4 flex flex-col mt-1">
                            <span>{`Order # ${item.reference_number}`}</span>
                            <span>
                              {changeDateFormat(
                                item.created_at,
                                'DD/MM/YYYY, h:mm a'
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className=" hidden c-lg:flex justify-center items-center">
                        <p className=" text-sm text[#000000] text-opacity-50 leading-4 text-center">
                          {changeDateFormat(item.created_at, ' Do MMM  YY')}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className=" text-center text-primary    flex justify-center items-center text-lg">
                          {item?.account_pricing?.total_earnings?.formatted
                            ? item?.account_pricing?.total_earnings?.formatted
                            : item.grand_total.formatted}
                        </p>
                      </div>
                      <div className="hidden xs:flex justify-center items-center">
                        <button className=" px-6 py-1   font-bold ">
                          {currentOrderStatus(item.order_status)}
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full h-full mx-auto  mt-8">
          <div className="animate-pulse flex space-x-4 mt-5">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-[90%]"></div>
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersItem;
