import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import tradly from 'tradly';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { EditProfile_text } from '../../constant/Translation/EditProfile_text';
import { General_text } from '../../constant/Translation/General';
import { UserInfo } from '../../store/feature/authSlice';

export const saveChange = (
  firstName,
  lastName,
  imagePath,
  files,
  setShowError,
  setError_message,
  setLoading,
  auth_key,
  userId,
  dispatch,
  setShowSuccess,
  setSuccess_message,
  dialcode,
  number,
  selectedCountryId
) => {
  setLoading(true);
  if (firstName === null || firstName?.replace(/\s/g, '').length <= 0) {
    setLoading(false);
    setShowError(true);
    setError_message(
      `${EditProfile_text.editprofile.firstname} ${General_text.general.required}`
    );
  }
  if (lastName === null || lastName?.replace(/\s/g, '').length <= 0) {
    setLoading(false);
    setShowError(true);
    setError_message(
      `${EditProfile_text.editprofile.lastname} ${General_text.general.required}`
    );
  }

  if (dialcode && number && dialcode !== '' && number !== '') {
    if (!isValidPhoneNumber(`+${number}`)) {
      setLoading(false);
      setShowError(true);
      setError_message(EditProfile_text.editprofile.enter_valid_phoneno);
      return false;
    }
  }

  if (files !== null) {
    axios
      .post('/api/generateS3ImageURL', {
        data: {
          files: [
            {
              name: files.name,
              type: files.type,
            },
          ],
        },
      })
      .then((response) => {
        if (!response.data.error) {
          const fileURL = response.data.result[0];
          const path = fileURL.signedUrl;
          const ImagePath = fileURL.fileUri;
          fetch(path, {
            method: 'put',
            headers: {
              ContentType: files.type,
            },
            body: files,
          }).then((res) => {
            const userData = {
              user: {
                first_name: firstName,
                last_name: lastName,
                profile_pic: ImagePath,
              },
            };

            if (number && number && dialcode !== '' && number !== '') {
              userData.user.mobile = number.slice(dialcode.length);
              userData.user.dial_code = dialcode;
            }
            if (selectedCountryId) {
              userData.user.country_id = selectedCountryId;
            }

            axios
              .post('/api/user/update_user', { userId, userData })
              .then((res) => {
                dispatch(UserInfo({ userId, auth_key })).then((res) => {
                  if (!res.payload.code) {
                    push_data_layer({
                      event: 'edit_profile',
                      data: {
                        first_name: firstName,
                        last_name: lastName,
                      },
                    });
                    setLoading(false);
                    setShowSuccess(true);
                    setSuccess_message(EditProfile_text.editprofile.updated);
                  } else {
                    setShowError(true);
                    setError_message(response.payload.message);
                    setLoading(false);
                  }
                });
              })
              .catch((error) => {
                alert(error.response.data.message);
              });
          });
        } else {
          setShowError(true);
          setError_message(response.data.error.message);
          setLoading(false);
        }
      });
  } else {
    const userData = {
      user: {
        first_name: firstName,
        last_name: lastName,
      },
    };
    if (selectedCountryId) {
      userData.user.country_id = selectedCountryId;
    }

    if (number && dialcode !== '' && number !== '') {
      userData.user.mobile = number.slice(dialcode.length);
      userData.user.dial_code = dialcode;
    }
    if (imagePath?.path) {
      userData.user['profile_pic'] = imagePath.path;
    } else {
      userData.user['profile_pic'] = '';
    }
    axios
      .post('/api/user/update_user', { userId, userData })
      .then((res) => {
        dispatch(UserInfo({ userId, auth_key })).then((res) => {
          if (!res.payload.code) {
            push_data_layer({
              event: 'edit_profile',
              data: {
                first_name: firstName,
                last_name: lastName,
              },
            });
            setLoading(false);
            setShowSuccess(true);
            setSuccess_message(EditProfile_text.editprofile.updated);
          } else {
            setShowError(true);
            setError_message(response.payload.message);
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  }
};
